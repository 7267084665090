export enum Pathname {
    Dashboard = "/dashboard",
    User = "/users",
    Feed = "/feeds",
    Report = "/report",
    PointProduct = "/point-product",
    PointExchange = "/point-exchange",
    Notice = "/notice",
    Version = "/version",
    AdminRegister = "/admin-register",
}
