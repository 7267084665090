import React from "react";
import {Route, Routes} from "react-router-dom";
import styled from "styled-components";
import {clearLocalStorageAccessToken, clearSessionStorageAccessToken} from "../utils/token";
import {baseApi} from "../api/base-api";
import {useAppDispatch} from "../app/reducers/hooks";
import {logoutAction} from "../app/reducers/profile-slice";
import Dashboard from "./Dashboard";
import Users from "./users/Users";
import Feeds from "./feeds/Feeds";
import Version from "./version/Version";
import Notice from "./notice/Notice";
import Report from "./report/Report";
import PointProduct from "./point/PointProduct";
import PointExchange from "./point/PointExchange";
import {Pathname} from "../model/Pathname";
import SidebarMenu from "../components/SidebarMenu";
import AdminRegister from "./admin/AdminRegister";

const Home = () => {
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        clearLocalStorageAccessToken();
        clearSessionStorageAccessToken();
        delete baseApi.defaults.headers.common["Authorization"];
        dispatch(logoutAction());
    }

    return (
        <Container>
            <Sidebar>
                <LogoContainer>
                    <LogoText>부라보 관리자</LogoText>
                </LogoContainer>
                <UserInfo>
                    <LogoutButton onClick={handleLogout}>로그아웃</LogoutButton>
                </UserInfo>
                <Menu>
                    <SidebarMenu />
                </Menu>
            </Sidebar>
            <Content>
                <Routes>
                    <Route path="/" element={<Users />} />
                    <Route path={Pathname.User} element={<Users />} />
                    <Route path={Pathname.Feed} element={<Feeds />} />
                    <Route path={Pathname.Report} element={<Report />} />
                    <Route path={Pathname.PointProduct} element={<PointProduct />} />
                    <Route path={Pathname.PointExchange} element={<PointExchange />} />
                    <Route path={Pathname.Notice} element={<Notice />} />
                    <Route path={Pathname.Version} element={<Version />} />
                    <Route path={Pathname.AdminRegister} element={<AdminRegister />} />

                    <Route path={Pathname.Dashboard} element={<Dashboard />} />
                </Routes>
            </Content>
        </Container>
    );
};

// Styled Components
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  font-family: 'Pretendard', sans-serif;
  background-color: #f7f8fc;
`;

const Sidebar = styled.div`
  width: 220px;
  background-color: #2f3e4e;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  color: #fff;
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; // 수평 가운데 정렬
  margin-bottom: 30px;
`;

const LogoText = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  color: #ecf0f1;
`;

const UserInfo = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoutButton = styled.button`
  background-color: #e74c3c;
  border: none;
  color: #fff;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.9em;

  &:hover {
    background-color: #c0392b;
  }
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
`;

const Content = styled.div`
  flex: 1;
  background-color: #f7f8fc;
  overflow-y: auto;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
`;

export default Home;
