import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Box, Button, Dialog, DialogContent, Divider, IconButton, Paper, Typography} from "@mui/material";
import {toast} from "react-toastify";
import {feedApi} from "../../api/feed-api";
import {userApi} from "../../api/user-api";
import {FeedResponse} from "../../types/feed/response/FeedResponse";

interface FeedDetailDialogProps {
    open: boolean;
    feedId: string | null;
    onClose: () => void;
    onActionComplete: () => void;
    highlightCommentId?: string | null;
}

const FeedDetailDialog: React.FC<FeedDetailDialogProps> = ({ open, feedId, onClose, onActionComplete, highlightCommentId }) => {
    const [feedDetail, setFeedDetail] = useState<FeedResponse | null>(null);
    const [openImageIndex, setOpenImageIndex] = useState<number | null>(null);

    const handleImageClick = (index: number) => {
        setOpenImageIndex(index);
    };

    const handleClose = () => {
        setOpenImageIndex(null);
    };

    const fetchFeedDetail = async () => {
        if (!feedId) return;
        try {
            const response = await feedApi.getFeedDetail(feedId);
            setFeedDetail(response.data);
        } catch (error) {
        }
    };

    const handleDeleteFeed = async () => {
        if (!feedId) return;
        const confirm = window.confirm("이 피드를 삭제하시겠습니까? 삭제하면 복구할 수 없습니다.");
        if (!confirm) return;
        try {
            await feedApi.deleteFeed(feedId);
            toast.success("피드가 삭제되었습니다.");
            onActionComplete();
            onClose();
        } catch (error) {
        }
    };

    const handleDisableFeedWriter = async () => {
        if (!feedDetail) return;
        const confirm = window.confirm("작성자를 비활성화하면 모든 게시글과 댓글이 비활성화됩니다. 계속하시겠습니까?");
        if (!confirm) return;
        try {
            await userApi.blockUser(feedDetail.writeUser.userId);
            toast.success("작성자가 비활성화되었습니다.");
            onActionComplete();
            onClose();
        } catch (error) {
        }
    };

    const handleDeleteComment = async (commentId: string) => {
        const confirm = window.confirm("이 댓글을 삭제하시겠습니까? 삭제하면 복구할 수 없습니다.");
        if (!confirm) return;
        try {
            await feedApi.deleteComment(commentId);
            toast.success("댓글이 삭제되었습니다.");
            onActionComplete();
            fetchFeedDetail();
        } catch (error) {
        }
    };

    const handleDisableCommentWriter = async (userId: string) => {
        const confirm = window.confirm("작성자를 비활성화하면 모든 게시글과 댓글이 비활성화됩니다. 계속하시겠습니까?");
        if (!confirm) return;
        try {
            await userApi.blockUser(userId);
            toast.success("댓글 작성자가 비활성화되었습니다.");
            onActionComplete();
            fetchFeedDetail();
        } catch (error) {
        }
    };

    useEffect(() => {
        if (open) fetchFeedDetail();
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogContentWrapper>
                {feedDetail ? (
                    <Box>
                        {/* 작성자 정보 */}
                        <AuthorInfo>
                            <ProfileImage src={feedDetail?.writeUser?.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`} />
                            <Box ml={2}>
                                <Typography variant="h6">{feedDetail.writeUser.nickname}</Typography>
                                <Typography variant="body2" color="textSecondary">{feedDetail.createdAt}</Typography>
                            </Box>
                        </AuthorInfo>

                        {/* 피드 내용 */}
                        <FeedContent>
                            <Typography variant="body1">{feedDetail.content}</Typography>
                            <ImageGrid>
                                {feedDetail.images.map((image, index) => (
                                    <ImagePreview
                                        key={index}
                                        src={image}
                                        alt={`피드 이미지 ${index + 1}`}
                                        onClick={() => handleImageClick(index)}
                                    />
                                ))}
                            </ImageGrid>
                        </FeedContent>

                        {/* 피드 액션 버튼 */}
                        <ActionButtons>
                            <Button variant="contained" color="error" onClick={handleDeleteFeed}>
                                피드 삭제하기
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleDisableFeedWriter}>
                                작성자 비활성화
                            </Button>
                        </ActionButtons>

                        <Divider sx={{ my: 2 }} />

                        {/* 댓글 섹션 */}
                        <Typography variant="h6" gutterBottom>
                            댓글
                        </Typography>
                        {feedDetail.comments.length > 0 ? (
                            feedDetail.comments.map((comment) => (
                                <CommentCard key={comment.commentId} isHighlighted={comment.commentId === highlightCommentId}>
                                <CommentHeader>
                                        <Box display="flex" alignItems="center">
                                            <ProfileImage src={comment.writeUser?.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`} />
                                            <Box ml={2}>
                                                <Typography variant="subtitle2">{comment.writeUser.nickname}</Typography>
                                                <Typography variant="body2" color="textSecondary">{comment.createdAt}</Typography>
                                            </Box>
                                        </Box>
                                    </CommentHeader>
                                    <CommentContent>{comment.content}</CommentContent>
                                    <ActionButtons>
                                        <Button variant="contained" color="error" size="small" onClick={() => handleDeleteComment(comment.commentId)}>
                                            댓글 삭제
                                        </Button>
                                        <Button variant="outlined" color="secondary" size="small" onClick={() => handleDisableCommentWriter(comment.writeUser.userId)}>
                                            작성자 비활성화
                                        </Button>
                                    </ActionButtons>
                                </CommentCard>
                            ))
                        ) : (
                            <Typography color="textSecondary">댓글이 없습니다.</Typography>
                        )}
                    </Box>
                ) : (
                    <Typography>피드 정보를 불러오는 중입니다...</Typography>
                )}
            </DialogContentWrapper>

            {openImageIndex !== null && (
                <Dialog open={openImageIndex !== null} onClose={handleClose} fullWidth>
                    <DialogContent>
                        <CloseButton onClick={handleClose}>
                            닫기
                        </CloseButton>
                        <ImageFullscreen src={feedDetail?.images[openImageIndex]} alt={`확대된 이미지 ${openImageIndex + 1}`} />
                    </DialogContent>
                </Dialog>
            )}
        </Dialog>
    );
};

export default FeedDetailDialog;

// 스타일 정의
const DialogContentWrapper = styled(DialogContent)`
  background-color: #f9f9f9;
`;

const AuthorInfo = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #ebedf3;
`;

const FeedContent = styled(Box)`
  margin-bottom: 20px;
`;

const ImageGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 10px;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ebedf3;
`;

const ActionButtons = styled(Box)`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const CommentCard = styled(Paper)<{ isHighlighted?: boolean }>`
  padding: 10px;
  margin-bottom: 15px;
  border-left: 4px solid #1976d2;
  border-radius: 8px;
  background-color: ${({ isHighlighted }) => (isHighlighted ? "#ffccc6" : "#fff")};
  transition: background-color 0.3s ease;
`;

const CommentHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const CommentContent = styled(Typography)`
  margin: 10px 0;
  font-size: 0.95rem;
  color: #333;
`;

const ImageFullscreen = styled.img`
  width: 100%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 8px;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px; /* 정사각형 모양에 약간의 둥근 모서리 */
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: translateY(-2px); /* 살짝 위로 올라가는 효과 */
  }

  &:active {
    transform: translateY(0); /* 클릭 시 원래 위치로 */
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3); /* 클릭 시 그림자 감소 */
  }
`;
