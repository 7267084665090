import React, {useState, useEffect, useRef} from "react";
import styled from "styled-components";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Typography,
    Grid, Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import { pointApi } from "../../api/point-api";
import { imageApi } from "../../api/image-api";
import {AddPointProductRequest} from "../../types/point/request/AddPointProductRequest";
import {PointProductResponse} from "../../types/point/response/PointProductResponse";

const PointProduct: React.FC = () => {
    const [products, setProducts] = useState<PointProductResponse[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [name, setName] = useState<string>("");
    const [stock, setStock] = useState<string>("");
    const [point, setPoint] = useState<string>("");
    const [imageUrl, setImageUrl] = useState<string>("");
    const [uploading, setUploading] = useState(false);

    const fetchProducts = async () => {
        try {
            const response = await pointApi.getProducts();
            setProducts(response.data);
        } catch (error) {
            toast.error("포인트 상품 목록을 불러오는 데 실패했습니다.");
        }
    };

    const handleUploadImage = async (files: FileList | null) => {
        if (!files || files.length === 0) return;

        setUploading(true);
        try {
            const response = await imageApi.uploadImages(files);
            setImageUrl(response.data[0]); // 첫 번째 업로드된 이미지 URL만 사용
            toast.success("이미지가 업로드되었습니다.");
        } catch (error) {
            toast.error("이미지 업로드에 실패했습니다.");
        } finally {
            setUploading(false);
        }
    };

    const handleAddProduct = async () => {
        if (!name || !stock || !point || !imageUrl) {
            toast.error("모든 필드를 입력해야 합니다.");
            return;
        }

        try {
            if (Number(stock) <= 0 || Number(point) <= 0) {
                toast.error("재고량과 필요 포인트는 0보다 커야 합니다.");
                return;
            }

            const request: AddPointProductRequest = {
                name,
                stock: Number(stock),
                point: Number(point),
                image: imageUrl,
            };

            await pointApi.addProduct(request);
            toast.success("포인트 상품이 등록되었습니다.");
            fetchProducts(); // 목록 갱신
            setDialogOpen(false); // 다이얼로그 닫기
            resetForm(); // 입력값 초기화
        } catch (error) {
            toast.error("포인트 상품 등록에 실패했습니다.");
        }
    };

    const resetForm = () => {
        setName("");
        setStock("");
        setPoint("");
        setImageUrl("");
    };

    const handleNumberInputChange = (
        setter: React.Dispatch<React.SetStateAction<string>>
    ) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        // 🔥 입력값이 빈 값이거나, 숫자로만 이루어진 경우에만 상태 업데이트
        if (inputValue === "" || /^[0-9]*$/.test(inputValue)) {
            setter(inputValue);
        }
    };

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const handleBoxClick = () => {
        fileInputRef.current?.click();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            handleUploadImage(files);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                포인트 상품 관리
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setDialogOpen(true)}
                sx={{ marginBottom: "16px" }}
            >
                상품 등록
            </Button>
            <Divider sx={{ my: 2 }} />
            <GridContainer>
                {products.map((product) => (
                    <ProductCard key={product.id}>
                        <ProductImage src={product.image} alt={product.name} />
                        <ProductInfo>
                            <ProductName title={product.name}>{product.name}</ProductName>
                            <Typography variant="body2">재고: {product.stock}</Typography>
                            <Typography variant="body2">포인트: {product.point}</Typography>
                        </ProductInfo>
                    </ProductCard>
                ))}
            </GridContainer>

            {/* 상품 등록 다이얼로그 */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>포인트 상품 등록</DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField
                            label="상품명"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                        />
                        <TextField
                            label="재고량"
                            type="text"
                            value={stock}
                            onChange={handleNumberInputChange(setStock)}
                            fullWidth
                        />
                        <TextField
                            label="필요 포인트"
                            type="text"
                            value={point}
                            onChange={handleNumberInputChange(setPoint)}
                            fullWidth
                        />
                        <Box>
                            <Typography variant="subtitle1">이미지 업로드</Typography>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleUploadImage(e.target.files)}
                                disabled={uploading}
                            />
                            {imageUrl ? (
                                <Box mt={2}>
                                    <ImagePreview src={imageUrl} alt="Uploaded Image" />
                                </Box>
                            ) : (
                                <Box
                                    mt={2}
                                    textAlign="center"
                                    border="1px dashed #ccc"
                                    p={2}
                                    borderRadius="8px"
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                    onClick={handleBoxClick}
                                >
                                    <Typography variant="body2" color="textSecondary">
                                        이미지를 드래그하거나 클릭하여 업로드하세요
                                    </Typography>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleUploadImage(e.target.files)}
                                        disabled={uploading}
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt={2}>
                            <Button variant="outlined" color="secondary" onClick={() => setDialogOpen(false)}>
                                취소
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddProduct}
                                disabled={uploading}
                            >
                                등록
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default PointProduct;

// 스타일 정의
const GridContainer = styled(Box)`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
`;

const ProductCard = styled(Box)`
  border: 1px solid #ebebeb;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const ProductInfo = styled(Box)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

const ProductName = styled(Typography)`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 최대 2줄 */
  -webkit-box-orient: vertical;
  min-height: 48px; /* 두 줄 높이 */
`;

const ImagePreview = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ccc;
`;
