import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
    Box,
    Button,
    Chip,
    Divider,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {FeedCategory, FeedResponse, FeedStatus} from "../../types/feed/response/FeedResponse";
import {feedApi} from "../../api/feed-api";
import {toast} from "react-toastify";
import FeedDetailDialog from "./FeedDetailDialog";

const StyledTableContainer = styled(TableContainer)`
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

const StyledTable = styled(Table)`
  min-width: 100%;
`;

const StyledTableHead = styled(TableHead)`
  background-color: #f5f5f5;
`;

const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
`;

const StyledTableCell = styled(TableCell)`
`;

const SmallTextTableCell = styled(TableCell)`
  font-size: 0.5rem;
`;

const Feeds: React.FC = () => {
    const [feeds, setFeeds] = useState<FeedResponse[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [authorName, setAuthorName] = useState("");
    const [content, setContent] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedFeedId, setSelectedFeedId] = useState<string | null>(null);
    const [open, setOpen] = useState(false);

    const handleOpenDialog = (feedId: string) => {
        setSelectedFeedId(feedId);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedFeedId(null);
    };

    const fetchFeeds = async () => {
        if (startDate && !/^\d{4}-\d{2}-\d{2}$/.test(startDate)) {
            toast.error("날짜는 yyyy-MM-dd 형식이어야 합니다.");
        }

        if (endDate && !/^\d{4}-\d{2}-\d{2}$/.test(endDate)) {
            toast.error("날짜는 yyyy-MM-dd 형식이어야 합니다.");
        }

        setLoading(true);
        try {
            const data = await feedApi.getFeeds({
                page: page - 1,
                size: 10, // 기본값
                authorName: authorName ? authorName : undefined,
                content: content ? content : undefined,
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
            });
            setFeeds(data.contents);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleActionComplete = () => {
        fetchFeeds(); // 삭제, 비활성화 후 리스트를 다시 불러옴
    };

    useEffect(() => {
        fetchFeeds();
    }, [page]);

    const handleDeleteFeed = async (feedId: string) => {
        try {
            if (window.confirm("피드를 삭제하시겠습니까?")) {
                await feedApi.deleteFeed(feedId);
                fetchFeeds();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleClear = async () => {
        setStartDate("");
        setEndDate("");
        setAuthorName("");
        setContent("");
    }

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                게시글 관리
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box mb={3} display="flex" gap={2} alignItems="center">
                <TextField
                    label="작성자 이름 검색"
                    variant="outlined"
                    value={authorName}
                    onChange={(e) => setAuthorName(e.target.value)}
                />
                <TextField
                    label="내용 검색"
                    variant="outlined"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />
                <TextField
                    label="시작일 (yyyy-MM-dd)"
                    variant="outlined"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="종료일 (yyyy-MM-dd)"
                    variant="outlined"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <Button variant="contained" color="primary" onClick={() => {
                    setPage(1)
                    fetchFeeds();
                }}>
                    검색
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#e1e1e1",
                        color: "#444",
                        "&:hover": {
                            backgroundColor: "#d6d6d6",
                        },
                    }}
                    onClick={() => handleClear()}
                >
                    초기화
                </Button>
            </Box>

            <StyledTableContainer as="div">
                <StyledTable>
                    <StyledTableHead>
                        <TableRow>
                            <StyledTableCell>피드 ID</StyledTableCell>
                            <StyledTableCell>카테고리</StyledTableCell>
                            <StyledTableCell>작성자</StyledTableCell>
                            <StyledTableCell>내용</StyledTableCell>
                            <StyledTableCell>좋아요 수</StyledTableCell>
                            <StyledTableCell>댓글 수</StyledTableCell>
                            <StyledTableCell>운동 일차</StyledTableCell>
                            <StyledTableCell>조회수</StyledTableCell>
                            <StyledTableCell>상태</StyledTableCell>
                            <StyledTableCell>작성일</StyledTableCell>
                            <StyledTableCell>삭제</StyledTableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={9} align="center">
                                    데이터 로딩 중...
                                </TableCell>
                            </TableRow>
                        ) : (
                            feeds.map((feed) => (
                                <StyledTableRow
                                    key={feed.feedId}
                                    onClick={() => handleOpenDialog(feed.feedId)} // 셀 클릭으로 Dialog 열기
                                >
                                    <SmallTextTableCell>{feed.feedId}</SmallTextTableCell>
                                    <StyledTableCell>{feed.category === FeedCategory.EXERCISE ? "운동 인증" : "일반"}</StyledTableCell>
                                    <StyledTableCell>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <img
                                                src={feed.writeUser.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`}
                                                alt="프로필"
                                                style={{
                                                    width: 36,
                                                    height: 36,
                                                    minWidth: 36,
                                                    minHeight: 36,
                                                    borderRadius: "50%",
                                                    border: "1px solid #EBEEF3",
                                                }}
                                            />
                                            {feed.writeUser.nickname}
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ minWidth: "200px", maxWidth: "200px", wordWrap: "break-word", whiteSpace: "normal" }}>
                                        {feed.content.length > 100 ? `${feed.content.slice(0, 100)}...` : feed.content}
                                    </StyledTableCell>
                                    <StyledTableCell>{feed.likeCount}</StyledTableCell>
                                    <StyledTableCell>{feed.commentCount}</StyledTableCell>
                                    <StyledTableCell>{feed.dayOfExercise ?? "-"}</StyledTableCell>
                                    <StyledTableCell>{feed.viewCount}</StyledTableCell>
                                    <StyledTableCell>
                                        <Chip
                                            label={feed.status === FeedStatus.ACTIVE ? "활성" : feed.status === FeedStatus.BLOCKED ? "비활성" : "삭제됨"}
                                            style={{
                                                backgroundColor: feed.status === FeedStatus.ACTIVE ? "#4caf50" : "#d26565",
                                                color: "#fff",
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>{feed.createdAt}</StyledTableCell>
                                    <StyledTableCell>
                                        {feed.status !== FeedStatus.DELETED && (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteFeed(feed.feedId);
                                                }}
                                            >
                                                삭제
                                            </Button>
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>

            <FeedDetailDialog
                open={open}
                feedId={selectedFeedId}
                onClose={handleCloseDialog}
                onActionComplete={handleActionComplete}
                highlightCommentId={null}
            />

            <Pagination
                count={totalPages}
                page={page}
                onChange={(e, value) => setPage(value)}
                color="primary"
                sx={{ mt: 3 }}
            />
        </Box>
    );
};

export default Feeds;
