import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
    Box,
    Button,
    Chip, Divider,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {reportApi} from "../../api/report-api";
import {ReportResponse, ReportStatus} from "../../types/report/response/ReportResponse";
import {toast} from "react-toastify";
import FeedDetailDialog from "../feeds/FeedDetailDialog";
import {TargetContentType} from "../../types/report/enum/TargetContentType";
import UserDetailDialog from "./UserDetailDialog";
import {feedApi} from "../../api/feed-api";
import {StyledTable, StyledTableCell, StyledTableContainer, StyledTableHead} from "../../components/Table";

const Report: React.FC = () => {
    const [reports, setReports] = useState<ReportResponse[]>([]);
    const [status, setStatus] = useState<ReportStatus | undefined>(ReportStatus.REPORTED); // 상태 필터
    const [page, setPage] = useState(1); // 현재 페이지
    const [totalPages, setTotalPages] = useState(1); // 총 페이지 수
    const [selectedReport, setSelectedReport] = useState<ReportResponse | null>(null);
    const [openFeedDialog, setOpenFeedDialog] = useState(false);
    const [openUserDialog, setOpenUserDialog] = useState(false);
    const [selectedCommentId, setSelectedCommentId] = useState<string | null>(null);
    const [feedId, setFeedId] = useState<string | null>(null); // 피드 ID 상태

    const fetchReports = async () => {
        try {
            const response = await reportApi.getPagedReports({
                page: page - 1, // API는 0-based, Pagination은 1-based
                size: 10,
                status,
            });
            setReports(response.contents);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error("Failed to fetch reports:", error);
        }
    };

    const handleViewTarget = async (report: ReportResponse) => {
        try {
            if (report.targetContentType === TargetContentType.PROFILE) {
                setOpenUserDialog(true);
            } else if (report.targetContentType === TargetContentType.FEED) {
                setFeedId(report.targetContentId); // FEED ID 설정
                setSelectedCommentId(null); // 댓글 ID 초기화
                setOpenFeedDialog(true);
            } else if (report.targetContentType === TargetContentType.COMMENT) {
                // COMMENT 타입: 댓글 ID로 피드 ID 조회
                const response = await feedApi.getFeedIdByCommentId(report.targetContentId);
                setFeedId(response); // 피드 ID 설정
                setSelectedCommentId(report.targetContentId); // 댓글 ID 설정
                setOpenFeedDialog(true);
            }
            setSelectedReport(report);
        } catch (error) {
            console.error("Failed to handle view target:", error);
        }
    };

    const handleConfirmReport = async (reportId: string) => {
        try {
            await reportApi.confirmReport(reportId);
            toast.success("신고가 확인 처리되었습니다.");
            fetchReports(); // 처리 후 리스트 갱신
        } catch (error) {
        }
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        fetchReports();
    }, [status, page]);

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                신고 관리
            </Typography>
            <Typography variant="body1">
                상세 보기에서 비활성화 등의 액션을 해도 [확인 처리] 버튼을 통해 처리를 해야합니다.
            </Typography>
            <Divider sx={{ my: 2 }} />

            <Box mb={3} display="flex" alignItems="center" gap={2}>
                {Object.values(ReportStatus).map((statusValue) => (
                    <Chip
                        key={statusValue}
                        label={statusValue === ReportStatus.REPORTED ? "미처리" : "처리 완료"}
                        color={status === statusValue ? "primary" : "default"}
                        onClick={() => setStatus(statusValue)}
                    />
                ))}
                <Chip
                    label="전체"
                    color={!status ? "primary" : "default"}
                    onClick={() => setStatus(undefined)}
                />
            </Box>

            <StyledTableContainer>
                <StyledTable>
                    <StyledTableHead>
                        <TableRow>
                            <StyledTableCell>신고 대상</StyledTableCell>
                            <StyledTableCell>신고자</StyledTableCell>
                            <StyledTableCell>신고 사유</StyledTableCell>
                            <StyledTableCell>신고 컨텐츠 타입</StyledTableCell>
                            <StyledTableCell>상태</StyledTableCell>
                            <StyledTableCell>조치</StyledTableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {reports.map((report) => (
                            <TableRow key={report.id} onClick={() => handleViewTarget(report)}>
                                <StyledTableCell>
                                    <UserInfo>
                                        <ProfileImage
                                            src={report.targetUser.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`}
                                            alt="프로필 이미지"
                                        />
                                        <Typography variant="body2">{report.targetUser.nickname}</Typography>
                                    </UserInfo>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <UserInfo>
                                        <ProfileImage
                                            src={report.reportUser.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`}
                                            alt="프로필 이미지"
                                        />
                                        <Typography variant="body2">{report.reportUser.nickname}</Typography>
                                    </UserInfo>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: "200px", wordWrap: "break-word", whiteSpace: "normal" }}>
                                    {report.reason}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {report.targetContentType === TargetContentType.FEED
                                        ? "피드"
                                        : report.targetContentType === TargetContentType.COMMENT
                                            ? "댓글"
                                            : report.targetContentType === TargetContentType.PROFILE
                                                ? "유저 프로필"
                                                : report.targetContentType}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Chip
                                        label={report.status === "REPORTED" ? "신고됨" : "처리됨"}
                                        color={report.status === "REPORTED" ? "error" : "success"}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    {report.status === "REPORTED" && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleConfirmReport(report.id);
                                            }}
                                        >
                                            확인 처리
                                        </Button>
                                    )}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>

            <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{ mt: 3 }}
            />

            {openFeedDialog && selectedReport && (
                <FeedDetailDialog
                    open={openFeedDialog}
                    feedId={
                        selectedReport.targetContentType === TargetContentType.FEED
                            ? selectedReport.targetContentId // FEED의 경우 직접 전달
                            : feedId // COMMENT의 경우 조회한 피드 ID
                    }
                    onClose={() => {
                        setOpenFeedDialog(false);
                        setSelectedCommentId(null);
                        setFeedId(null); // 피드 ID 초기화
                    }}
                    onActionComplete={fetchReports}
                    highlightCommentId={selectedCommentId} // 댓글 ID 전달
                />
            )}

            {openUserDialog && selectedReport && (
                <UserDetailDialog
                    open={openUserDialog}
                    userId={selectedReport.targetUser.userId}
                    onClose={() => setOpenUserDialog(false)}
                    onActionComplete={fetchReports}
                />
            )}
        </Box>
    );
};

export default Report;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  border: 1px solid #ebebeb;
`;

const UserInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
