import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Chip, Divider,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import styled from "styled-components";
import {userApi} from "../../api/user-api";
import {AdminUserResponse} from "../../types/user/response/AdminUserResponse";
import {UserStatus} from "../../types/user/enum/UserStatus";
import {BasePageDto} from "../../api/base-response";
import {toast} from "react-toastify";
import UpdatePointDialog from "./UpdatePointDialog";

// 스타일 정의
const StyledTableContainer = styled(TableContainer)`
  margin: 20px 0;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

const StyledTable = styled(Table)`
  //min-width: 800px;
  width: 100%;
`;

const StyledTableHead = styled(TableHead)`
  background-color: #f5f5f5;
`;

const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f9f9f9;
  }
`;

const StyledTableCell = styled(TableCell)`
  min-width: 120px;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #EBEEF3;
  }
`;

// 컴포넌트
const Users: React.FC = () => {
    const [page, setPage] = useState(1); // 현재 페이지
    const [totalPages, setTotalPages] = useState(1); // 총 페이지 수
    const [users, setUsers] = useState<AdminUserResponse[]>([]); // 유저 목록
    const [loading, setLoading] = useState(false); // 로딩 상태
    const [keyword, setKeyword] = useState(""); // 닉네임 검색
    const [status, setStatus] = useState<UserStatus | "">(""); // 상태 검색
    const [selectedUser, setSelectedUser] = useState<{ id: string; points: number } | null>(null);
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = (userId: string, currentPoints: number) => {
        setSelectedUser({ id: userId, points: currentPoints });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setSelectedUser(null);
        setOpenDialog(false);
    };

    const handlePointsUpdated = () => {
        fetchUsers()
    };

    // 유저 데이터 로드 함수
    const fetchUsers = async () => {
        setLoading(true);
        try {
            const requestStatus = status === "" ? null : status; // 빈 값은 null로 처리
            const response: BasePageDto<AdminUserResponse> = await userApi.getUsers({ page: page - 1, size: 10, keyword: keyword, status: requestStatus });
            setUsers(response.contents);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error("유저 목록을 가져오는 중 에러 발생:", error);
        } finally {
            setLoading(false);
        }
    };

    // 페이지 변경 시 데이터 로드
    useEffect(() => {
        fetchUsers();
    }, [page]);

    const handleDisableUser = async (userId: string) => {
        const confirmDisable = window.confirm(
            "비활성화를 하면 모든 게시글과 댓글 또한 비활성화 됩니다.\n복구하려면 개발자의 힘이 많이 듭니다.\n정말 비활성화하시겠습니까?"
        );

        if (confirmDisable) {
            try {
                await userApi.blockUser(userId);
                toast.success("유저가 성공적으로 비활성화되었습니다.");
                fetchUsers(); // 유저 목록을 다시 불러옵니다.
            } catch (error) {
                toast.error("비활성화에 실패했습니다. 다시 시도해주세요.");
            }
        }
    };

    const handleSearch = () => {
        setPage(1);
        fetchUsers();
    };

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                유저 관리
            </Typography>
            <Divider sx={{ my: 2 }} />

            {/* 검색 필드 */}
            <Box mb={3} display="flex" alignItems="center" gap={2}>
                <TextField
                    label="닉네임 / 이름 / 전화번호 통합 검색"
                    variant="outlined"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    sx={{ width: "300px" }}
                />
                <TextField
                    label="상태"
                    variant="outlined"
                    select
                    value={status}
                    onChange={(e) => setStatus(e.target.value as UserStatus | "")}
                    sx={{ width: "200px" }}
                >
                    <MenuItem value="">전체</MenuItem>
                    <MenuItem value={UserStatus.ACTIVE}>활성</MenuItem>
                    <MenuItem value={UserStatus.BLOCKED}>비활성</MenuItem>
                    <MenuItem value={UserStatus.DELETED}>탈퇴</MenuItem>
                </TextField>
                <Button variant="contained" color="primary" onClick={handleSearch}>
                    검색
                </Button>
            </Box>

            <StyledTableContainer as="div">
                <StyledTable>
                    <StyledTableHead>
                        <TableRow>
                            <StyledTableCell>닉네임</StyledTableCell>
                            <StyledTableCell>목표</StyledTableCell>
                            <StyledTableCell>운동 #</StyledTableCell>
                            <StyledTableCell>최근 운동일시</StyledTableCell>
                            <StyledTableCell>포인트</StyledTableCell>
                            <StyledTableCell>이름</StyledTableCell>
                            <StyledTableCell>성별</StyledTableCell>
                            <StyledTableCell>생년월일</StyledTableCell>
                            <StyledTableCell>전화번호</StyledTableCell>
                            <StyledTableCell>상태</StyledTableCell>
                            <StyledTableCell>비활성화</StyledTableCell>
                            <StyledTableCell>포인트 수정</StyledTableCell>
                            <StyledTableCell>최근 방문일시</StyledTableCell>
                            <StyledTableCell>계정 생성일시</StyledTableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={13} align="center">
                                    데이터 로딩 중...
                                </TableCell>
                            </TableRow>
                        ) : (
                            users.map((user) => (
                                <StyledTableRow key={user.userId}>
                                    <StyledTableCell>
                                        <ProfileContainer>
                                            <img
                                                src={
                                                    user.profileImageUrl
                                                        ? user.profileImageUrl
                                                        : `${process.env.PUBLIC_URL}/assets/default_profile.png`
                                                }
                                                alt="프로필"
                                            />
                                            {user.nickname}
                                        </ProfileContainer>
                                    </StyledTableCell>
                                    <StyledTableCell>{user.goal || "-"}</StyledTableCell>
                                    <StyledTableCell>{user.dayOfExercise} 일</StyledTableCell>
                                    <StyledTableCell>{user.exercisedAt || "운동 기록 없음"}</StyledTableCell>
                                    <StyledTableCell>{user.point.toLocaleString()} P</StyledTableCell>
                                    <StyledTableCell>{user.name || "-"}</StyledTableCell>
                                    <StyledTableCell>
                                        {user.gender === 'male' ? '남' : user.gender === 'female' ? '여' : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {user.birthyear && user.birthdate
                                            ? `${user.birthyear}-${user.birthdate.slice(0, 2)}-${user.birthdate.slice(2, 4)}`
                                            : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell>{user.phoneNumber || "-"}</StyledTableCell>
                                    <StyledTableCell>
                                        <Chip
                                            label={
                                                user.status === UserStatus.ACTIVE
                                                    ? "활성"
                                                    : user.status === UserStatus.BLOCKED
                                                        ? "비활성"
                                                        : "탈퇴"
                                            }
                                            style={{
                                                backgroundColor:
                                                    user.status === UserStatus.ACTIVE
                                                        ? "#4caf50"
                                                        : user.status === UserStatus.BLOCKED
                                                            ? "#f57c00"
                                                            : "#d26565",
                                                color: "#fff",
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {user.status === UserStatus.ACTIVE && (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={() => handleDisableUser(user.userId)}
                                            >
                                                비활성화
                                            </Button>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpenDialog(user.userId, user.point)}
                                        >
                                            POINT+-
                                        </Button>
                                    </StyledTableCell>
                                    <StyledTableCell>{user.recentVisitedAt || "-"}</StyledTableCell>
                                    <StyledTableCell>{user.createdAt}</StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>

            {selectedUser && (
                <UpdatePointDialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    userId={selectedUser.id}
                    currentPoints={selectedUser.points}
                    onPointsUpdated={handlePointsUpdated}
                />
            )}

            <Pagination
                count={totalPages}
                page={page}
                onChange={(e, value) => setPage(value)}
                color="primary"
                sx={{ mt: 3 }}
            />
        </Box>
    );
};

export default Users;
