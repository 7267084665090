import React from "react";
import styled from "styled-components";

const Dashboard: React.FC = () => {
    return (
        <Container>
            <Header>관리자 대시보드</Header>

            <Announcement>⚠️ 2025년 7월 공개 예정 ️⚠️</Announcement>

            <Grid>
                <StatCard title="총 사용자 수" value="12,345명" />
                <StatCard title="활성 사용자 수" value="1,234명" />
                <StatCard title="오늘 게시글" value="56개" />
                <StatCard title="오늘 방문자" value="789명" />
                <StatCard title="오늘 신고된 게시물" value="5건" />
                <StatCard title="해결된 신고" value="3건" />
            </Grid>

            {/* 위젯 */}
            <WidgetRow>
                <LargeWidget>
                    <WidgetTitle>게시글 트렌드</WidgetTitle>
                    <Placeholder>📈 그래프</Placeholder>
                </LargeWidget>

                <SmallWidgets>
                    <Widget>
                        <WidgetTitle>신규 가입자</WidgetTitle>
                        <Placeholder>
                            <ul>
                                <li></li>
                            </ul>
                        </Placeholder>
                    </Widget>

                    <Widget>
                        <WidgetTitle>인기 게시글</WidgetTitle>
                        <Placeholder>
                            <ul>
                                <li></li>
                            </ul>
                        </Placeholder>
                    </Widget>
                </SmallWidgets>
            </WidgetRow>
        </Container>
    );
};

// 통계 카드 컴포넌트
const StatCard: React.FC<{ title: string; value: string }> = ({ title, value }) => (
    <Card>
        <CardTitle>{title}</CardTitle>
        <CardValue>{value}</CardValue>
    </Card>
);

// styled-components
const Container = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  font-family: 'Pretendard', sans-serif;
  min-height: 100vh;
`;

const Header = styled.h1`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;

const Announcement = styled.div`
    margin: 40px 0;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #555;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
`;

const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
`;

const CardTitle = styled.h3`
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
`;

const CardValue = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #5DAE72;
`;

const WidgetRow = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const LargeWidget = styled.div`
  flex: 2;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SmallWidgets = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Widget = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const WidgetTitle = styled.h4`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
`;

const Placeholder = styled.div`
  font-size: 14px;
  color: #777;
  text-align: center;
`;

export default Dashboard;
