import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material";
import {toast} from "react-toastify";
import {imageApi} from "../../api/image-api";
import {SaveNoticeRequest} from "../../types/notice/request/SaveNoticeRequest";
import {NoticeResponse} from "../../types/notice/response/NoticeResponse";
import {noticeApi} from "../../api/notice-api";
import theme from "../../theme";

interface NoticeDialogProps {
    open: boolean;
    notice?: NoticeResponse | null; // 부모에서 전달받는 공지 데이터
    onClose: () => void;
    onActionComplete: () => void; // Callback to refresh the list
}

const NoticeDialog: React.FC<NoticeDialogProps> = ({
                                                       open,
                                                       notice,
                                                       onClose,
                                                       onActionComplete,
                                                   }) => {
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [images, setImages] = useState<string[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);

    // 초기값 설정
    useEffect(() => {
        if (open) {
            setTitle(notice?.title || "");
            setContent(notice?.content || "");
            setImages(notice?.images || []);
        } else {
            // Dialog 닫힐 때 초기화
            setTitle("");
            setContent("");
            setImages([]);
        }
    }, [open, notice]);

    // 이미지 업로드 처리
    const handleUploadImages = async (files: FileList | null) => {
        if (!files || files.length === 0) return;

        setUploading(true);
        try {
            const response = await imageApi.uploadImages(files);
            setImages((prev) => [...prev, ...response.data]);
            toast.success("이미지가 업로드되었습니다.");
        } catch (error) {
        } finally {
            setUploading(false);
        }
    };

    // 공지 저장
    const handleSaveNotice = async () => {
        if (!title || !content) {
            toast.error("제목과 내용을 입력해주세요.");
            return;
        }

        const request: SaveNoticeRequest = { title, content, images };

        try {
            if (notice) {
                // 수정 모드
                await noticeApi.updateNotice(notice.id, { title, content, images });
                toast.success("공지사항이 수정되었습니다.");
            } else {
                // 생성 모드
                await noticeApi.saveNotice(request);
                toast.success("공지사항이 작성되었습니다.");
            }

            onActionComplete();
            onClose();
        } catch (error) {
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{notice ? "공지사항 수정" : "공지사항 작성"}</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                        label="제목"
                        variant="outlined"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="내용"
                        variant="outlined"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                    />
                    <Box>
                        <Typography variant="subtitle1">이미지 업로드</Typography>
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={(e) => handleUploadImages(e.target.files)}
                            disabled={uploading}
                        />
                        <ImagePreviewContainer>
                            {images.map((url, index) => (
                                <ImagePreview key={index} src={url} alt={`Uploaded ${index}`} />
                            ))}
                        </ImagePreviewContainer>
                    </Box>
                    <DialogContentText color={theme.palette.foundation.primary}>{notice ? "*수정할 경우 이미 발송된 알림의 내용이 수정되지는 않습니다." : "*작성할 경우 모든 사용자에게 '즉시' 알림을 전송합니다."}</DialogContentText>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Button variant="outlined" color="secondary" onClick={onClose}>
                            취소
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveNotice}
                            disabled={uploading}
                        >
                            {notice ? "수정 완료" : "작성 완료"}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default NoticeDialog;

// 스타일 정의
const ImagePreviewContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
  border: 1px solid #ccc;
`;
