import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    Box,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Pagination,
    Chip, Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import { pointApi } from "../../api/point-api";
import { PointExchangeResponse } from "../../types/point/response/PointExchangeResponse";
import {PointExchangeStatus} from "../../types/point/enum/PointExchangeStatus";
import {
    StyledTable,
    StyledTableCell,
    StyledTableContainer,
    StyledTableHead,
    StyledTableRow
} from "../../components/Table";

const PointExchange: React.FC = () => {
    const [exchanges, setExchanges] = useState<PointExchangeResponse[]>([]);
    const [status, setStatus] = useState<PointExchangeStatus | undefined>(PointExchangeStatus.REQUEST);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const fetchExchanges = async () => {
        try {
            const response = await pointApi.getExchanges({
                page: page - 1,
                size: 10,
                status,
            });
            setExchanges(response.contents);
            setTotalPages(response.totalPages);
        } catch (error) {
        }
    };

    const handleConfirmExchange = async (exchangeId: string) => {
        try {
            await pointApi.confirmExchange(exchangeId);
            toast.success("포인트 교환이 확인 처리되었습니다.");
            fetchExchanges();
        } catch (error) {
        }
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        fetchExchanges();
    }, [page, status]);

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                상품 교환 관리
            </Typography>
            <Divider sx={{ my: 2 }} />

            {/* 상태 필터 */}
            <Box mb={3} display="flex" alignItems="center" gap={2}>
                <Chip
                    label="미처리"
                    color={status === PointExchangeStatus.REQUEST ? "primary" : "default"}
                    onClick={() => setStatus(PointExchangeStatus.REQUEST)}
                />
                <Chip
                    label="처리됨"
                    color={status === PointExchangeStatus.CONFIRM ? "primary" : "default"}
                    onClick={() => setStatus(PointExchangeStatus.CONFIRM)}
                />
                <Chip
                    label="전체"
                    color={!status ? "primary" : "default"}
                    onClick={() => setStatus(undefined)}
                />
            </Box>

            {/* 교환 내역 테이블 */}
            <StyledTableContainer>
                <StyledTable>
                    <StyledTableHead>
                        <TableRow>
                            <StyledTableCell>상품</StyledTableCell>
                            <StyledTableCell>수량</StyledTableCell>
                            <StyledTableCell>이름</StyledTableCell>
                            <StyledTableCell>전화번호</StyledTableCell>
                            <StyledTableCell>소모 포인트</StyledTableCell>
                            <StyledTableCell>상태</StyledTableCell>
                            <StyledTableCell>조치</StyledTableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {exchanges.map((exchange) => (
                            <TableRow key={exchange.id}>
                                <StyledTableCell>
                                    <ProductInfo>
                                        <ProductImage
                                            src={exchange.product.image}
                                            alt={exchange.product.name}
                                        />
                                        <ProductName>{exchange.product.name}</ProductName>
                                    </ProductInfo>
                                </StyledTableCell>
                                <StyledTableCell>{exchange.quantity.toLocaleString()}</StyledTableCell>

                                <StyledTableCell>
                                    <UserInfo>
                                        <ProfileImage
                                            src={exchange.user.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`}
                                            alt={exchange.user.nickname}
                                        />
                                        <Typography>{exchange.user.nickname}</Typography>
                                    </UserInfo>
                                </StyledTableCell>
                                <StyledTableCell>{exchange.phone}</StyledTableCell>
                                <StyledTableCell>{exchange.consumePoint.toLocaleString()}</StyledTableCell>
                                <StyledTableCell>
                                    <Chip
                                        label={exchange.status === PointExchangeStatus.REQUEST ? "미처리" : "처리됨"}
                                        color={exchange.status === PointExchangeStatus.REQUEST ? "error" : "success"}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    {exchange.status === PointExchangeStatus.REQUEST && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleConfirmExchange(exchange.id)}
                                        >
                                            확인 처리
                                        </Button>
                                    )}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>

            {/* 페이지네이션 */}
            <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{ mt: 3 }}
            />
        </Box>
    );
};

export default PointExchange;

const UserInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #ebebeb;
`;

const ProductInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
`;

const ProductName = styled(Typography)`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
  border: 1px solid #ebebeb;
`;
