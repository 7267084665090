import { AxiosInstance } from "axios";
import { BaseResponse } from "./base-response";
import { NoticeResponse } from "../types/notice/response/NoticeResponse";
import { baseApi } from "./base-api";
import {SaveNoticeRequest} from "../types/notice/request/SaveNoticeRequest";

class NoticeApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async getNotices(): Promise<BaseResponse<NoticeResponse[]>> {
        return await this._api.get("/api/notices");
    }

    async saveNotice(request: SaveNoticeRequest): Promise<void> {
        await this._api.post("/api/admin/notice", request);
    }

    async updateNotice(noticeId: string, request: SaveNoticeRequest): Promise<void> {
        await this._api.put(`/api/admin/notice/${noticeId}`, request);
    }

    async deleteNotice(noticeId: string): Promise<void> {
        await this._api.delete(`/api/admin/notice/${noticeId}`);
    }
}

export const noticeApi = new NoticeApi(baseApi);
