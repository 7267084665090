import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    primary: "#FB493D",
    secondary: "#00ff00",
    error: "#ff0000"
  },
  icon: {
    fill: {
      addressFill: "../assets/Icons/fill/adress-fill.svg",
      cameraFill: "../assets/Icons/fill/camera-fill.svg",
      carFill: "../assets/Icons/fill/car-fill.svg",
      deleteFill: "../assets/Icons/fill/delete-fill.svg",
      errorWarningFill: "../assets/Icons/fill/error-warning-fill.svg",
      eyeFill: "../assets/Icons/fill/eye-fill.svg",
      heartFill: "../assets/Icons/fill/heart-fill.svg",
      mapDetailFill: "../assets/Icons/fill/map-detail-fill.svg",
      mapPinTimeFill: "../assets/Icons/fill/map-pin-time-fill.svg",
      navigationFill: "../assets/Icons/fill/navigation-fill.svg",
      phoneFill: "../assets/Icons/fill/phone-fill.svg",
      scheduleFill: "../assets/Icons/fill/schedule-fill.svg",
      timeFill: "../assets/Icons/fill/time-fill.svg",
    },
    line: {
      carLine: "../assets/Icons/line/car-line.svg",
      checkLine: "../assets/Icons/line/check-line.svg",
      closeLine: "../assets/Icons/line/close-line.svg",
      closeSmallLine: "../assets/Icons/line/close-small-line.svg",
      eraserLine: "../assets/Icons/line/eraser-line.svg",
      mapDetailLine: "../assets/Icons/line/map-detail-line.svg",
      menuLine: "../assets/Icons/line/menu-line.svg",
      phoneLine: "../assets/Icons/line/phone-line.svg",
      scheduleFirLine: "../assets/Icons/line/schedule-fir-line.svg",
      scheduleSecLine: "../assets/Icons/line/schedule-sec-line.svg",
      timeLine: "../assets/Icons/line/time-line.svg",
    },
  },
  palette: {
    primitives: {
      /* gray */
      white: "#FFFFFF",
      gray50: "#F5F6F9",
      gray100: "#EBEEF3",
      gray200: "#E0E3E9",
      gray300: "#BEC3CB",
      gray400: "#979DA8",
      gray500: "#626A76",
      gray600: "#4D535C",
      gray700: "#3F444E",
      gray800: "#2A2F36",
      gray900: "#1C2026",
      black: "#000000",
      /* red */
      red50: "#FFF6F7",
      red100: "#FEEBEC",
      red200: "#FFCFD8",
      red300: "#FFB7C4",
      red400: "#FD8593",
      red500: "#F9603E",
      red600: "#F14800",
      red700: "#96011B",
      /* yellow */
      yellow50: "#FFFCF2",
      yellow100: "#FFF7D9",
      yellow200: "#FEECA6",
      yellow300: "#FDDF66",
      yellow400: "#FCCA00",
      yellow500: "#E7AD18",
      yellow600: "#D6A11B",
      yellow700: "#9E6E0F",
      /* green */
      green50: "#F5FFF8",
      green100: "#E1F5E8",
      green200: "#BAF0D3",
      green300: "#77DBAB",
      green400: "#3AB171",
      green500: "#249A62",
      green600: "#148D4C",
      green700: "#005738",
      /* blue */
      blue50: "#F3FAFF",
      blue100: "#DBF1FF",
      blue200: "#BEE5FF",
      blue300: "#64BEFC",
      blue400: "#0D8CE2",
      blue500: "#075EE1",
      blue600: "#14439C",
      blue700: "#102C60",
      /* purple */
      purple50: "#F5F6FE",
      purple100: "#E5EAFF",
      purple200: "#CBD0FE",
      purple300: "#A3ACFA",
      purple400: "#625FED",
      purple500: "#5C34CE",
      purple600: "#4E0EB6",
      purple700: "#30167A",
    },
    foundation: {
      primary: "#FB493D",
      primaryA: "#000000",
      primaryB: "#FCCA00",
      negative: "#D72142",
      toStart: "#BEC3CB",
      pickUp: "#0D8CE2",
      dropOff: "#FCCA00",
      resting: "#3AB171",
      toEnd: "#625FED",
    },
    gradient: {
      pickUp:
        "linear-gradient(180deg, #FFFFFF 0%, #F3FAFF 34.12%, #DBF1FF 100%)",
      dropOff: "linear-gradient(180deg, #FFFFFF 0%, #FFF7D9 91.72%)",
      resting: "linear-gradient(180deg, #FFFFFF 0%, #E1F5E8 91.72%)",
      toEnd: "linear-gradient(180deg, #FFFFFF 0%, #E6EAFF 91.72%)",
    },
    action: {
      active: "80%",
      hover: "rgba(0, 0, 0, 0.04)",
    },
  },
  typography: {
    fontFamily: "Pretendard",
    headline1: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 48,
      lineHeight: 56,
      letterSpacing: "-1.5px",
    },

    headline2: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 36,
      lineHeight: 48,
      letterSpacing: "0px",
    },

    headline3: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 30,
      lineHeight: 40,
      letterSpacing: "0.5px",
    },

    headline4: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 36,
      letterSpacing: "0.18px",
    },

    headline5: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 32,
      letterSpacing: "0.15px",
    },

    subtitle1: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 18,
      lineHeight: 28,
      letterSpacing: "0.15px",
    },

    subtitle2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 24,
      letterSpacing: "0.15px",
    },

    body1: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 28,
      lineHeight: 40,
      letterSpacing: "0px",
    },

    body2: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 24,
      lineHeight: 36,
      letterSpacing: "0px",
    },

    body3: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 20,
      lineHeight: 30,
      letterSpacing: "0px",
    },

    body4: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 24,
      letterSpacing: "0px",
    },
    button1: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 28,
      lineHeight: 28,
      letterSpacing: "1.25px",
    },

    button2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 24,
      letterSpacing: "1.25px",
    },

    caption: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 24,
      letterSpacing: "0.4px",
    },

    overline: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 14,
      letterSpacing: "1.5px",
    },

    number: {
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: 32,
      lineHeight: 28,
      letterSpacing: "1.5px",
    },
  },
};

export default theme;
