import React, {useState} from "react";
import styled from "styled-components";
import {Box, Button, TextField, Typography} from "@mui/material";
import {toast} from "react-toastify";
import {userApi} from "../../api/user-api";

const AdminRegister: React.FC = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");

    const handleRegister = async () => {
        if (!username || !password || !name) {
            toast.error("모든 필드를 입력해주세요.");
            return;
        }

        try {
            await userApi.registerAdmin({ username, password, name });
            toast.success("관리자가 성공적으로 등록되었습니다.");
            resetForm();
        } catch (error) {
        }
    };

    const resetForm = () => {
        setUsername("");
        setPassword("");
        setName("");
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                관리자 등록
            </Typography>
            <FormContainer>
                <TextField
                    label="아이디"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="비밀번호"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="이름"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                />
                <Box display="flex" justifyContent="space-between" mt={3}>
                    <Button variant="outlined" color="secondary" onClick={resetForm}>
                        초기화
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleRegister}>
                        등록
                    </Button>
                </Box>
            </FormContainer>
        </Container>
    );
};

export default AdminRegister;

// 스타일 정의
const Container = styled(Box)`
  padding: 32px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;
