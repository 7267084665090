import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Box,
} from "@mui/material";
import { toast } from "react-toastify";
import {userApi} from "../../api/user-api";

interface UpdatePointDialogProps {
    open: boolean;
    onClose: () => void;
    userId: string;
    currentPoints: number;
    onPointsUpdated: () => void;
}

const UpdatePointDialog: React.FC<UpdatePointDialogProps> = ({
                                                                 open,
                                                                 onClose,
                                                                 userId,
                                                                 currentPoints,
                                                                 onPointsUpdated,
                                                             }) => {
    const [amount, setAmount] = useState<string>("");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        // 🔥 입력 값이 빈 값이거나, 숫자 또는 "-"로만 이루어진 경우에만 상태 업데이트
        if (inputValue === "" || /^-?\d*$/.test(inputValue)) {
            setAmount(inputValue);
        }
    };

    const handleSubmit = async () => {
        const numericAmount = parseInt(amount, 10); // 🔥 숫자로 변환
        if (isNaN(numericAmount) || numericAmount === 0) {
            toast.error("값을 확인하세요");
            return;
        }

        const isGivingPoints = numericAmount > 0;
        const reason = isGivingPoints
            ? "관리자에 의한 포인트 지급"
            : "관리자에 의한 포인트 차감";

        try {
            await userApi.updateUserPoint(userId, { amount: numericAmount, reason });
            toast.success("포인트가 성공적으로 업데이트되었습니다.");
            onPointsUpdated();
            onClose();
        } catch (error) {
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>포인트 수정</DialogTitle>
            <DialogContent>
                <Typography>현재 포인트: {currentPoints.toLocaleString()} P</Typography>
                <Box mt={2}>
                    <TextField
                        fullWidth
                        label="지급/차감 금액"
                        type="text"
                        value={amount}
                        onChange={handleInputChange}
                        placeholder="금액 입력"
                        helperText="양수로 입력하면 지급, 음수로 입력하면 차감됩니다."
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    취소
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    disabled={amount === "" || parseInt(amount, 10) === 0}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdatePointDialog;
