import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button, Divider } from "@mui/material";
import { toast } from "react-toastify";
import { systemApi } from "../../api/system-api";

const Version: React.FC = () => {
    const [forceUpdateVersion, setForceUpdateVersion] = useState<string>("");
    const [currentVersion, setCurrentVersion] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchVersion = async () => {
        try {
            setIsLoading(true);
            const response = await systemApi.getSystemVersion();
            setForceUpdateVersion(response.forceUpdateVersionName);
            setCurrentVersion(response.currentVersionName);
        } catch (error) {
            toast.error("시스템 버전 정보를 불러오는 데 실패했습니다.");
        } finally {
            setIsLoading(false);
        }
    };

    const updateVersion = async () => {
        try {
            await systemApi.updateSystemVersion({
                forceUpdateVersion,
                currentVersion,
            });
            toast.success("시스템 버전 정보가 업데이트되었습니다.");
            fetchVersion(); // 업데이트 후 데이터 다시 가져오기
        } catch (error) {
            toast.error("시스템 버전 정보를 업데이트하는 데 실패했습니다.");
        }
    };

    useEffect(() => {
        fetchVersion();
    }, []);

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                시스템 버전 관리
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box display="flex" flexDirection="column" gap={3} maxWidth="400px">
                <TextField
                    label="강제 업데이트 버전"
                    value={forceUpdateVersion}
                    onChange={(e) => setForceUpdateVersion(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="현재 버전"
                    value={currentVersion}
                    onChange={(e) => setCurrentVersion(e.target.value)}
                    fullWidth
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={updateVersion}
                    disabled={isLoading}
                >
                    {isLoading ? "업데이트 중..." : "업데이트"}
                </Button>
            </Box>
        </Box>
    );
};

export default Version;

