import { AxiosInstance } from "axios";
import {BasePageDto, BaseResponse} from "./base-response";
import { baseApi } from "./base-api";
import { PointProductResponse } from "../types/point/response/PointProductResponse";
import { AddPointProductRequest } from "../types/point/request/AddPointProductRequest";
import {PointExchangeStatus} from "../types/point/enum/PointExchangeStatus";
import {PointExchangeResponse} from "../types/point/response/PointExchangeResponse";

class PointApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async getProducts(): Promise<BaseResponse<PointProductResponse[]>> {
        return await this._api.get("/api/products");
    }

    async addProduct(request: AddPointProductRequest): Promise<void> {
        await this._api.post("/api/admin/product", request);
    }

    async getExchanges(params: { page: number; size: number; status?: PointExchangeStatus }): Promise<BasePageDto<PointExchangeResponse>> {
        const response = await this._api.get<BasePageDto<PointExchangeResponse>>("/api/admin/points", { params });
        return response.data;
    }

    async confirmExchange(exchangeId: string): Promise<void> {
        await this._api.post(`/api/admin/points/${exchangeId}/confirm`);
    }
}

export const pointApi = new PointApi(baseApi);
