import React from "react";
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {Pathname} from "../model/Pathname";

interface MenuConfig {
    to: string;
    label: string;
}

const MenuItem = styled.li`
  margin: 15px 0;
`;

const StyledLink = styled(Link)<{ isActive: boolean }>`
  text-decoration: none;
  color: ${(props) => (props.isActive ? "#ffffff" : "#c0c7d1")};
  font-size: 1em;
  display: block;
  padding: 12px;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;

  background-color: ${(props) => (props.isActive ? "#5a6d85" : "transparent")};

  &:hover {
    background-color: #3c4b5e;
    color: #ffffff;
  }
`;

const SidebarMenu: React.FC = () => {
    const location = useLocation();

    const createMenuItems = (menus: MenuConfig[]) => {
        return menus.map(({ to, label }) => {
            const isActive = location.pathname.startsWith(to + "/") || location.pathname === to;

            return (
                <MenuItem key={to}>
                    <StyledLink to={to} isActive={isActive}>
                        {label}
                    </StyledLink>
                </MenuItem>
            );
        });
    };

    const menuConfig = [
        { to: Pathname.Feed, label: "📋 게시글 관리" },
        { to: Pathname.Report, label: "⚠️ 신고 관리" },
        { to: Pathname.PointProduct, label: "🎁 포인트 상품 관리" },
        { to: Pathname.PointExchange, label: "📦 상품 교환 관리" },
        { to: Pathname.Notice, label: "📢 공지사항 관리" },
        { to: Pathname.Version, label: "⚙️ 버전 관리" },
    ];

    return <>
        <MenuItem>
            <StyledLink to={Pathname.User} isActive={location.pathname.startsWith(Pathname.User + "/") || location.pathname === Pathname.User || location.pathname === "/"}>
                👥 유저 관리
            </StyledLink>
        </MenuItem>
        {createMenuItems(menuConfig)}
    </>;
};

export default SidebarMenu;
