import {CommentResponse} from "./CommentResponse";
import {UserResponse} from "../../user/response/UserResponse";

export enum FeedCategory {
    NORMAL = "NORMAL",
    EXERCISE = "EXERCISE",
}

export enum FeedStatus {
    ACTIVE = "ACTIVE",
    BLOCKED = "BLOCKED",
    DELETED = "DELETED",
}

export interface FeedResponse {
    feedId: string;
    category: FeedCategory;
    content: string;
    images: string[];
    likeCount: number;
    commentCount: number;
    viewCount: number;
    dayOfExercise?: number;
    status: FeedStatus;
    createdAt: string;
    writeUser: UserResponse;
    comments: CommentResponse[];
    isLikedByUser: boolean;
}
