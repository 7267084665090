import styled from "styled-components";
import {Table, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

export const StyledTableContainer = styled(TableContainer)`
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

export const StyledTable = styled(Table)`
  min-width: 100%;
`;

export const StyledTableHead = styled(TableHead)`
  background-color: #f5f5f5;
`;

export const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
`;

export const StyledTableCell = styled(TableCell)`
`;
