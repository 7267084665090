import {AxiosInstance} from "axios";
import {FeedSearchParams} from "../types/feed/request/FeedSearchParams";
import {BasePageDto, BaseResponse} from "./base-response";
import {baseApi} from "./base-api";
import {FeedResponse} from "../types/feed/response/FeedResponse";

class FeedApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async getFeeds(params: FeedSearchParams): Promise<BasePageDto<FeedResponse>> {
        const response = await this._api.get<BasePageDto<FeedResponse>>("/api/admin/feeds", { params });
        return response.data;
    }

    async deleteFeed(feedId: string): Promise<void> {
        await this._api.delete(`/api/admin/feeds/${feedId}`);
    }

    async getFeedDetail(feedId: string): Promise<BaseResponse<FeedResponse>> {
        return await this._api.get(`/api/admin/feeds/${feedId}`);
    }

    async deleteComment(commentId: string): Promise<void> {
        return await this._api.delete(`/api/admin/comments/${commentId}`);
    }

    async getFeedIdByCommentId(commentId: string): Promise<string> {
        const response = await this._api.get<string>(`/api/admin/comments/${commentId}/feed-id`);
        return response.data;
    }
}

export const feedApi = new FeedApi(baseApi);
