import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import { noticeApi } from "../../api/notice-api";
import { NoticeResponse } from "../../types/notice/response/NoticeResponse";
import ImageViewerDialog from "./ImageViewerDialog";
import NoticeDialog from "./NoticeDialog";

const Notice: React.FC = () => {
    const [notices, setNotices] = useState<NoticeResponse[]>([]);
    const [imageViewerOpen, setImageViewerOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [selectedNotice, setSelectedNotice] = useState<NoticeResponse | null>(null);

    const fetchNotices = async () => {
        try {
            const response = await noticeApi.getNotices();
            setNotices(response.data);
        } catch (error) {
        }
    };

    const handleDeleteNotice = async (noticeId: string) => {
        try {
            await noticeApi.deleteNotice(noticeId);
            toast.success("공지사항이 삭제되었습니다.");
            fetchNotices();
        } catch (error) {
        }
    };

    const handleEditNotice = (notice: NoticeResponse) => {
        setSelectedNotice(notice);
        setEditDialogOpen(true);
    };

    const handleViewNotice = (notice: NoticeResponse) => {
        setSelectedNotice(notice);
        setViewDialogOpen(true);
    };

    const handleViewImage = (image: string) => {
        setSelectedImage(image);
        setImageViewerOpen(true);
    };

    useEffect(() => {
        fetchNotices();
    }, []);

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                공지사항 관리
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    setSelectedNotice(null); // 작성 모드
                    setEditDialogOpen(true);
                }}
            >
                공지사항 작성
            </Button>
            <Divider sx={{ my: 2 }} />
            <Box>
                {notices.map((notice) => (
                    <NoticeCard key={notice.id}>
                        <Box>
                            <Typography variant="h6">{notice.title}</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                {notice.createdAt}
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 2, overflow: "hidden", textOverflow: "ellipsis", maxHeight: "4.5em" }}>
                                {notice.content}
                            </Typography>
                        </Box>
                        <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
                            {notice.images.map((image, index) => (
                                <NoticeImage
                                    key={index}
                                    src={image}
                                    alt={`공지사항 이미지 ${index + 1}`}
                                    onClick={() => handleViewImage(image)}
                                />
                            ))}
                        </Box>
                        <Box display="flex" gap={1} marginTop={"16px"}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleEditNotice(notice)} // 수정 버튼 동작
                            >
                                수정
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleDeleteNotice(notice.id)}
                            >
                                삭제
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => handleViewNotice(notice)} // 상세 보기 동작
                            >
                                상세 보기
                            </Button>
                        </Box>
                    </NoticeCard>
                ))}
            </Box>

            {viewDialogOpen && selectedNotice && (
                <Dialog open={viewDialogOpen} onClose={() => setViewDialogOpen(false)} fullWidth maxWidth="sm">
                    <DialogTitle>
                        {selectedNotice.title}
                        <CloseButton onClick={() => setViewDialogOpen(false)}>✕</CloseButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                            {selectedNotice.createdAt}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            {selectedNotice.content}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Box>
                            {selectedNotice.images.map((image, index) => (
                                <NoticeImage
                                    key={index}
                                    src={image}
                                    alt={`공지사항 이미지 ${index + 1}`}
                                    onClick={() => handleViewImage(image)}
                                />
                            ))}
                        </Box>
                    </DialogContent>
                </Dialog>
            )}

            {selectedImage && (
                <ImageViewerDialog
                    open={imageViewerOpen}
                    image={selectedImage}
                    onClose={() => setImageViewerOpen(false)}
                />
            )}

            <NoticeDialog
                open={editDialogOpen}
                notice={selectedNotice || null}
                onClose={() => {
                    setEditDialogOpen(false);
                    setSelectedNotice(null); // 선택 초기화
                }}
                onActionComplete={fetchNotices} // 수정 후 목록 갱신
            />
        </Box>
    );
};

export default Notice;

const NoticeCard = styled(Box)`
  padding: 16px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const NoticeImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.8);

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`;
