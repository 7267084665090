import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import {styled} from '@mui/material/styles';
import {useAppDispatch} from "../app/reducers/hooks";
import {userApi} from "../api/user-api";
import {baseApi} from "../api/base-api";
import {setLocalStorageAccessToken, setSessionStorageAccessToken, StorageKey} from "../utils/token";
import {loginAction} from "../app/reducers/profile-slice";

const Card = styled(MuiCard)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '450px',
    },
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const SignInContainer = styled(Stack)(({theme}) => ({
    padding: 20,
    marginTop: '10vh',
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundImage:
            'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {
            backgroundImage:
                'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
        }),
    },
}));

export default function SignIn(props: {
    disableCustomTheme?: boolean
}) {
    const dispatch = useAppDispatch();

    const [token, setToken] = React.useState<string>("");
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [rememberMe, setRememberMe] = React.useState(false);

    useEffect(() => {
        const accessToken =
            sessionStorage.getItem(StorageKey.AccessToken) || localStorage.getItem(StorageKey.AccessToken);

        if (accessToken !== null && accessToken !== "") {
            baseApi.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
            dispatch(loginAction());
        }
    }, [token])

    const handleSubmit = async (id: string, password: string) => {
        const signInPayload = {
            username: id,
            password: password
        };
        try {
            const response = await userApi.signIn(signInPayload);
            const token = response.data.accessToken;

            if (rememberMe) {
                setLocalStorageAccessToken(token);
            } else {
                setSessionStorageAccessToken(token);
            }

            setToken(response.data.accessToken);

            baseApi.defaults.headers.common["Authorization"] = "Bearer " + token;
        } catch (e) {
        }
    };

    const validateInputs = () => {
        const id = document.getElementById('id') as HTMLInputElement;
        const password = document.getElementById('password') as HTMLInputElement;

        if (!id.value) {
            setEmailError(true);
            setEmailErrorMessage("아이디를 입력하세요.")
            return
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }

        if (!password.value) {
            setPasswordError(true);
            setPasswordErrorMessage('비밀번호를 입력하세요.');
            return
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
        }

        handleSubmit(id.value, password.value)
    };

    return (
        <>
            <CssBaseline enableColorScheme/>
            <SignInContainer direction="column" justifyContent="space-between">
                <Card variant="outlined">
                    <Typography
                        component="h1"
                        variant="h6"
                        sx={{width: '100%', fontSize: 'clamp(2rem, 10vw, 1.15rem)'}}
                    >
                        부라보 관리자 시스템
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl>
                            <FormLabel>아이디</FormLabel>
                            <TextField
                                error={emailError}
                                helperText={emailErrorMessage}
                                id="id"
                                type="id"
                                name="id"
                                placeholder="아이디를 입력하세요"
                                autoComplete="username"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={emailError ? 'error' : 'primary'}
                                sx={{ariaLabel: '아이디'}}
                            />
                        </FormControl>
                        <FormControl>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <FormLabel htmlFor="password">비밀번호</FormLabel>
                            </Box>
                            <TextField
                                error={passwordError}
                                helperText={passwordErrorMessage}
                                name="password"
                                placeholder="••••••"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                required
                                fullWidth
                                variant="outlined"
                                color={passwordError ? 'error' : 'primary'}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value="remember"
                                    color="primary"
                                    checked={rememberMe}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRememberMe(event.target.checked)}
                                />
                            }
                            label="자동 로그인"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={validateInputs}
                        >
                            로그인
                        </Button>
                    </Box>
                </Card>
            </SignInContainer>
        </>
    );
}
